import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
const BlogPost = (props: LayoutProps) => {
  return (
    <Layout pageTitle={props.data.mdx.frontmatter.title}>
      <MDXRenderer>
        {props.data.mdx.body}
      </MDXRenderer>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`

export default BlogPost

interface LayoutProps{
  data: {mdx: {body:string, frontmatter: {title: string}}}
}